var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mr-4", attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-1",
                      attrs: { pressed: _vm.pdfAll, variant: "light" },
                      on: {
                        "update:pressed": function ($event) {
                          _vm.pdfAll = $event
                        },
                        click: function ($event) {
                          return _vm.toggleAllPermissions("pdf")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.pdfAll ? "Deseleziona" : "Seleziona") +
                          " tutti: PDF"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { pressed: _vm.csvAll, variant: "light" },
                      on: {
                        "update:pressed": function ($event) {
                          _vm.csvAll = $event
                        },
                        click: function ($event) {
                          return _vm.toggleAllPermissions("csv")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.csvAll ? "Deseleziona" : "Seleziona") +
                          " tutti: CSV"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("reports", {
            ref: _vm.tableRef,
            attrs: {
              fields: _vm.fields,
              repository: _vm.repository,
              resource: _vm.resource,
              filterName: _vm.filterName,
              filterOn: {
                byRelations: ["byUser"],
              },
              noActions: "",
              noPagination: "",
            },
          }),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "button",
                        variant: "lisaweb",
                        size: "sm",
                        title: "Salva Permessi",
                      },
                      on: { click: _vm.onPermissions },
                    },
                    [_vm._v("\n        Salva Permessi\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "lisaweb", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.back()
                        },
                      },
                    },
                    [
                      _c("b-icon-chevron-double-left", {
                        attrs: { "font-scale": "0.9" },
                      }),
                      _vm._v("Torna indietro"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }