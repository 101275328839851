var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mt-1" },
        [
          _c(
            "b-overlay",
            {
              attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center mt-5" },
                          [
                            _c("base-icon", {
                              attrs: {
                                name: "loading",
                                width: "35",
                                height: "35",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { attrs: { id: "cancel-label" } }, [
                              _vm._v("Operazione in corso..."),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3374301958
              ),
            },
            [
              !_vm.isLoading
                ? _c(
                    "div",
                    [
                      _c(
                        "b-card",
                        {
                          attrs: {
                            header: "Modifica configurazioni App",
                            "header-tag": "header",
                          },
                        },
                        [
                          _c(
                            "b-card-text",
                            _vm._l(
                              _vm.lisa_for_apps_reports,
                              function (report, index) {
                                return _c(
                                  "b-card",
                                  {
                                    key: index,
                                    staticClass: "mt-2",
                                    attrs: {
                                      header: `${report.formatted_title}`,
                                      "header-tag": "header",
                                    },
                                  },
                                  [
                                    _c("b-row", [
                                      _c(
                                        "div",
                                        { staticClass: "form-group col-md-3" },
                                        [
                                          _c("base-select", {
                                            attrs: {
                                              name: "estrai",
                                              label: "Estrai",
                                              options: [
                                                { value: "Y", text: "Sì" },
                                                { value: "N", text: "No" },
                                              ],
                                            },
                                            model: {
                                              value:
                                                _vm.form[_vm.rep][report.code]
                                                  .can_extract,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form[_vm.rep][
                                                    report.code
                                                  ],
                                                  "can_extract",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form[rep][report.code].can_extract",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "form-group col-md-3" },
                                        [
                                          _c("base-select", {
                                            attrs: {
                                              name: "mostra",
                                              label: "Mostra Premio",
                                              options: [
                                                { value: "Y", text: "Sì" },
                                                { value: "N", text: "No" },
                                              ],
                                            },
                                            model: {
                                              value:
                                                _vm.form[_vm.rep][report.code]
                                                  .show_premium,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form[_vm.rep][
                                                    report.code
                                                  ],
                                                  "show_premium",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form[rep][report.code].show_premium",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "py-2 mb-4" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "button",
                                variant: "lisaweb",
                                size: "sm",
                                disabled: !_vm.canVerb(_vm.resource, "update"),
                              },
                              on: { click: _vm.onSavePermissions },
                            },
                            [_vm._v("\n          Salva\n        ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "float-right",
                              attrs: { variant: "lisaweb", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.back()
                                },
                              },
                            },
                            [
                              _c("b-icon-chevron-double-left", {
                                attrs: { "font-scale": "0.9" },
                              }),
                              _vm._v("Torna indietro"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }